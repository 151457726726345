import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { EnrollerContext } from 'context/enroller/Enroller';
import PageLoader from 'components/layout/pageLoader/PageLoader';
import { getProfile, UserProfile } from '../../api/user';
import FormUserRequest from './FormUserRequest';
import Content from '../content/Content';
import SuccessRequest from './SuccessRequest';
import { ReponseUserStatus, retrieveUserStatus } from '../../api/institutionProxy';

function LayoutUserAffiliation() {
  const navigate = useNavigate();
  const { agoraID } = useParams<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [profile, setProfile] = useState<UserProfile>();
  const [success, setSuccess] = useState<boolean>(false);
  const { enroller } = React.useContext(EnrollerContext);

  useEffect(() => {
    const userCanBeAffiliated = (userStatus: ReponseUserStatus) => (
      userStatus.institution_affiliated === false
      || (
        userStatus.institution_affiliated
        && userStatus.roles?.includes('personnel')
        && userStatus.roles?.includes('patient') === false
      )
    );

    const verifyUserToAffiliateStatus = async (enrollerInstitutionID: string) => {
      if (
        window.authService !== undefined
        && enroller !== undefined
      ) {
        const token = await window.authService.getAccessToken();
        const userStatus = await retrieveUserStatus(agoraID, enrollerInstitutionID, token);
        if (
          userCanBeAffiliated(userStatus) === false
        ) {
          navigate('/');
        }
      }
    };

    if (enroller !== undefined) {
      verifyUserToAffiliateStatus(enroller.institutionID);
    }
  }, [enroller]);

  useEffect(() => {
    const loadUser = async (): Promise<void> => {
      try {
        setLoading(true);
        if (window.authService !== undefined && agoraID !== undefined) {
          const token = await window.authService.getAccessToken();
          if (token !== undefined) {
            setProfile(await getProfile(agoraID, token));
          }
        }
        setLoading(false);
      } catch (err) {
        navigate('/');
        setLoading(false);
      }
    };

    if (agoraID === undefined) {
      navigate('/');
    } else {
      loadUser();
    }
  }, []);

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmitRequest = () => {
    setSuccess(true);
  };

  const handleSubmitSuccess = () => {
    navigate('/');
  };

  return (
    !loading ? (
      <Content>
        {success === false ? (
          <FormUserRequest
            name={profile !== undefined ? profile.name : ''}
            agoraID={agoraID !== undefined ? agoraID : ''}
            onSubmit={handleSubmitRequest}
            onCancel={handleCancel}
          />
        ) : (
          <SuccessRequest
            name={profile !== undefined ? profile.name : ''}
            onSubmit={handleSubmitSuccess}
          />
        )}
      </Content>
    ) : (
      <PageLoader />
    )
  );
}

export default LayoutUserAffiliation;
