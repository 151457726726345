import React, { useMemo } from 'react';
import { Props, UserRegistrationContextType, UserToRegister } from './UserRegistrationContext.d';

const UserRegistrationContext = React.createContext<UserRegistrationContextType>({
  userToRegister: undefined,
  userToRegisterIsStaffOnly: false,
  setUserToRegister: () => Promise.reject(new Error('not implemented')),
});

function UserRegistrationContextProvider({
  children,
}: Readonly<Props>) {
  const [userToRegister, setUserToRegister] = React.useState<UserToRegister | undefined>();
  const userToRegisterIsStaffOnly = useMemo(() => (
    userToRegister?.status.roles !== undefined
      && userToRegister.status.roles.length === 1
      && userToRegister.status.roles[0] === 'personnel'
  ), [userToRegister]);

  const contextValue = useMemo(() => ({
    userToRegister,
    userToRegisterIsStaffOnly,
    setUserToRegister,
  }), [
    userToRegister,
    userToRegisterIsStaffOnly,
    setUserToRegister,
  ]);

  return (
    <UserRegistrationContext.Provider
      value={contextValue}
    >
      {children}
    </UserRegistrationContext.Provider>
  );
}

export { UserRegistrationContextProvider, UserRegistrationContext };
