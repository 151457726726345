import React, { useEffect } from 'react';
import { UserRegistrationContext } from 'context/userregistration/UserRegistrationContext';
import CardWrapper from 'components/layout/cardWrapper/CardWrapper';
import FormAgora from './FormAgora';
import styles from './LayoutFormAgora.module.scss';

function LayoutFormAgora(): JSX.Element {
  const { setUserToRegister } = React.useContext(UserRegistrationContext);

  useEffect(() => {
    setUserToRegister(undefined);
  }, []);

  return (
    <div className={styles.wrapper}>
      <CardWrapper>
        <div className='form-agora-container'>
          <FormAgora />
        </div>
      </CardWrapper>
    </div>
  );
}

export default LayoutFormAgora;
