import React, { useMemo, useCallback } from 'react';
import { Props, KeyContextType } from './KeyPair.d';

const KeyPairContext = React.createContext<KeyContextType>({
  publicJWK: undefined,
  privateKey: undefined,
  setKeyPair: () => Promise.reject(new Error('not implemented')),
});

function KeyPairContextProvider({
  children,
}: Readonly<Props>) {
  const [publicJWK, setPublicJWK] = React.useState<JsonWebKey | undefined>();
  const [privateKey, setPrivateKey] = React.useState<CryptoKey | undefined>();

  const setKeyPair = useCallback(async () => {
    const { subtle } = window.crypto;
    const { publicKey, privateKey: pKey } = await subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 4096,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256',
      },
      true,
      ['encrypt', 'decrypt'],
    );

    const publicJWKExported = await subtle.exportKey('jwk', publicKey);

    setPublicJWK(publicJWKExported);
    setPrivateKey(pKey);
  }, []);

  const contextValue = useMemo(() => ({
    publicJWK,
    privateKey,
    setKeyPair,
  }), [
    publicJWK,
    privateKey,
    setKeyPair,
  ]);

  return (
    <KeyPairContext.Provider
      value={contextValue}
    >
      {children}
    </KeyPairContext.Provider>
  );
}

export { KeyPairContextProvider, KeyPairContext };
