import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import './index.css';
import 'animations.scss';
import { EnrollerContextProvider } from 'context/enroller/Enroller';
import { KeyPairContextProvider } from 'context/keypair/KeyPair';
import { InstitutionsContextProvider } from 'context/institutions/Institutions';
import { UserRegistrationContextProvider } from 'context/userregistration/UserRegistrationContext';
import App from './components/app/App';
import AuthService from './services/AuthService';
import TokenService from './services/TokenService';
import i18n from './services/i18n';

declare global {
  interface Window {
    authService: AuthService | undefined;
    tokenService: TokenService | undefined;
  }
}

if (window.authService === undefined && window.location.pathname !== `${process.env.REACT_APP_BASE_PATH}/signin-callback.html`) {
  window.authService = new AuthService();
}

if (window.tokenService === undefined && window.location.pathname !== `${process.env.REACT_APP_BASE_PATH}/signin-callback.html`) {
  window.tokenService = new TokenService();
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback='... isloading'>
        <EnrollerContextProvider>
          <KeyPairContextProvider>
            <InstitutionsContextProvider>
              <UserRegistrationContextProvider>
                <App />
              </UserRegistrationContextProvider>
            </InstitutionsContextProvider>
          </KeyPairContextProvider>
        </EnrollerContextProvider>
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>,
);
