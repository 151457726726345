import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import { InstitutionsContextType, InstitutionsObject } from './Institutions.d';
import { retrieveAllInstitutions, Institution } from '../../api/institutionProxy';

const InstitutionsContext = React.createContext<InstitutionsContextType>({
  institutions: {},
  availableInstitutions: [],
  initializeInstitutions: async () => { throw new Error('not implemented'); },
});

type Props = {
  children: React.ReactNode,
};

const AVAILABLE_INSTITUTIONS = [
  '16-0895-249', // Centre Médical de Terre Bonne
  '22-7416-567', // Unilabs
  '33-5655-767', // John McManus
  '36-8271-458', // Permanence Médico-Cgurzgucake Rond-Point Plainpalais
  '67-1720-647', // Centre Jean-Violette
  '67-3577-284', // GHOL
  '75-2959-544', // Adesso
  '82-6694-267', // UCI Health
  '86-3946-547', // Arsanté
  '92-7835-975', // Centre d'Imagerie Médicale d'Eysin
  '96-9773-423', // 3R
];

function InstitutionsContextProvider({ children }: Readonly<Props>) {
  const [institutions, setInstitutions] = useState<InstitutionsObject>({});
  const [availableInstitutions, setAvailableInstitutions] = useState<Institution[]>([]);
  const initializeInstitutions = useCallback(async (token: string) => {
    const allInstitutions = await retrieveAllInstitutions(token);
    setInstitutions(allInstitutions.reduce((acc, institution) => {
      acc[institution.institutionID] = institution;
      return acc;
    }, {} as InstitutionsObject));
    setAvailableInstitutions(
      allInstitutions
        .filter((institution) => (
          AVAILABLE_INSTITUTIONS.includes(institution.institutionID))),
    );
  }, []);

  const contextValue = useMemo(() => ({
    institutions,
    availableInstitutions,
    initializeInstitutions,
  }), [institutions, availableInstitutions, initializeInstitutions]);

  return (
    <InstitutionsContext.Provider
      value={contextValue}
    >
      {children}
    </InstitutionsContext.Provider>
  );
}

export { InstitutionsContextProvider, InstitutionsContext };
